import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Row, Col, Container, Jumbotron, Figure} from 'react-bootstrap';

import '../assets/style.css';

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulOurServicesHomepageBoxes:{
        nodes:services
    }} = data

    return (
        <div className="service">  
            <Container> 
                <Row>
                    <div className="our-services">
                        <h2>Our services</h2> 
                        <span>Our team is dedicated to create and improve the user experience, interfaces and applications to complement our clients business needs.</span>
                    </div>
                    {services.map((services) => {
                        return <Col xs={12} md={6} lg={6} className="service-col">
                            <a href={`/services/${services.slug}/`}> 
                                <Jumbotron className={`service-box ${services.slug}`}  key={services.id} >
                                    <div className="service-content">
                                        <div className="service-img">
                                            <div className="service-img-ctn"><img width="100%" height="auto" src={services.image.fluid.src} alt="Logo service" /></div>
                                            <h3 className="service-title">{services.title}</h3>
                                        </div>
                                        <div className="service-text">
                                            {/* <img width="100%" height="auto" src={services.image.fluid.src} alt="Logo service" /> */}
                                            <h3 className="service-title">{services.title}</h3>
                                            <span className="service-info">{services.info.info}</span>
                                        </div>
                                    </div>
                                </Jumbotron> 
                            </a> 
                        </Col>
                    })}
                </Row>
            </Container>
            <Container>
                <div className="rad-banner">
                    <div className="banner-text">
                        <p className="rad-title">Sogody's Proprietary Tech</p>
                        <p className="description">
                            We turned our initial findings into a plan for implementing or integrating them into any online business. By automating processes and reducing daily time-consuming tasks, we support our client’s efforts to streamline their workflow.
                        </p>
                        <a href="/rad/" class="primary-btn-s btn btn-primary">Get started</a>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export const query = graphql`
{
    allContentfulOurServicesHomepageBoxes(filter: {node_locale: {eq: "en-US"}}, sort: {fields: createdAt}) {
        nodes {
            id
            title
            image {
                fluid(quality: 100) {
                    src
                }
            }
            slug
                info {
                    info
                }
        }
    }
}
`


