import React, {useState} from "react"
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import {  Button, Carousel, Figure} from 'react-bootstrap';

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulHomepageArticlesShowcase :{
        nodes:article
    }
} = data
const [index, setIndex] = useState(0);

const handleSelect = (selectedIndex, e) => {
  setIndex(selectedIndex);
};

    return (
        <div className="homepage-carousel">  
                <Carousel activeIndex={index} onSelect={handleSelect}>
                    {article.map((art)=>{ 
                        let body = null;
                        if (art.tag === "update") {
                            body = <div className="update-homepage">
                                        <h2><span className="title">{art.category}</span></h2>
                                        <p className="subtitle">{art.title}</p>
                                        {/* <hr className="hr-green-update"> */}

                                        {/* </hr> */}
                                        {/* <p className="author">By {art.article.author}</p> */}
                                        <Button href={`updates/${art.article.upSlug}/`} className="secondary-btn-s"> {art.btnTitle}
                                            <Figure className="read-more">
                                                    <Figure.Image   src={require('../assets/images/next_service.png')}  />
                                            </Figure>
                                        </Button>
                                    </div>;
                        } else if (art.tag === "case-study") {
                            body = <div className="case-study-homepage"> 
                                <h2><span className="title">{art.category}</span></h2>
                                <p className="subtitle">{art.title}</p>
                                <Button className="primary-btn-s" href={`work/${art.article.csSlug}/`}>{art.btnTitle}</Button></div>;
                        }
                        return ( <Carousel.Item>
                        {/* <img className="carousel-image img-fluid"  src={art.image.fluid.src}  src alt="Carousel img"/> */}
                        <img className="carousel-image img-fluid"  src={art.image.file.url} alt="Carousel img"/>
                        <div className="shadow"></div>  
                        <Carousel.Caption>    
                            <div className="banner-body"> 
                                {body}
                            </div>
                        </Carousel.Caption>
                        </Carousel.Item> )})}
                </Carousel>
        </div>
    )
}

export const query = graphql`
{
    allContentfulHomepageArticlesShowcase(filter: {node_locale: {eq: "en-US"}}, sort: {fields: updatedAt}) {
        nodes {
            title
            category
            btnTitle
            tag
            image {
                file {
                    url
                }
            }
            article {
            ... on ContentfulWorkSuccessStories {
                id
                csSlug
                csBtn
            }
            ... on ContentfulUpdates {
                upSlug
                author
            }
            }
        }
    }
}
`

// export const query = graphql`
// {
//     allContentfulHomepageArticlesShowcase(limit: 1, filter: {node_locale: {eq: "en-US"}}, sort: {fields: updatedAt}) {
//         nodes {
//             btnTitle
//             category
//             title
//             image {
//                 fluid {
//                     ...GatsbyContentfulFluid
//                 }
//             }
//         }
//     }
//     allContentfulBlogHomepage(filter: {node_locale: {eq: "en-US"}}, limit: 1, sort: {fields: updatedAt}) {
//         nodes {
//             btnTitle
//             category
//             image {
//                 fluid {
//                     ...GatsbyContentfulFluid
//                 }
//             }
//             title
//             blog {
//                 author
//                 upSlug
//             }
//         }
//     }
// }
// `


