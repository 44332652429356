

import React from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import storeez from '../assets/images/services/storeez-logo.svg';
import spell from '../assets/images/services/spell-logo.svg';
import storeezBanner from '../assets/images/services/storeez-banner.png'
import spellBanner from '../assets/images/services/spell-sell-banner.png'

export default function() {

    return (
      <div className="storeez-spell">
          <Container>
            <h1 className='title'>Sogody’s brand extensions</h1>
            <p className='description'>Spell&Sell and Storeez Studio are specialized teams that help you bridge the worlds of e-commerce and user experience. Take a look at their niche offerings.</p>
            <Row>
                <Col  xs={12} md={12} lg={6}>
                <div className="storeez-mini" style={{backgroundColor:"#F0F2F3" }}>
                    <img src={storeezBanner} className="storeez-banner" alt="Storeez banner"></img>
                    <img src={storeez} className="storeez-icon" alt="Storeez logo"></img>
                    <h1>Strategically evolving with Storeez Studio</h1>
                    <p> Drawing on your knowledge of the market and our knowledge of the online world, we visualise what you can experiment with to get the best experience.</p>
                    <Button href="#" className="storeez-btn">Coming soon</Button>
                    
                    {/* <img src={storeez} alt="Storeez logo"></img> */}
                </div>
                </Col>
                <Col xs={12} md={12} lg={6}>
                <div className="spell-sell" style={{backgroundColor:"#D0E8FF"}}> 
                    <img src={spellBanner} className="spell-sell-banner" alt="Spell&Sell banner"></img>
                    <img src={spell} className="spell-sell-icon" alt="Spell&Sell logo"></img>
                    <h1>Create an outstanding online store</h1>
                    <p>As a Shopify expert design and development agency, Spell & Sell provides data-driven, scalable e-commerce to help your online business grow.</p>
                    <Button href="#" className="spell-btn">Coming soon</Button>
                </div>
                </Col>
            </Row>
        </Container>
      </div>
    
    )
}
