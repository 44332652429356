import React from "react"
import Layout from '../components/Layout'
import Services from "../components/Services"
import Updates from "../components/Updates"
import CtaMeet from "../components/CtaMeet";
import Clients from "../components/Clients";
import CaseStudies from "../components/CaseStudies";
import HomepageCarousel from "../components/HomepageCarousel";
import { Container} from "react-bootstrap"; 
import Testimonials from "../components/Testimonials";
import { Seo } from "../components/Seo";
import metaImageOg from '../assets/images/og-image.jpg';
import '../assets/scss/main.scss';
import TermsBanner from "../components/TermsBanner";
import StoreezSpell from "../components/StoreezSpell";
import { useLocation } from "@reach/router"
// import { globalHistory } from "@reach/router";

export default ()=> {
  // useEffect(() => {
  //   console.log("GLobal, history");
  //   return globalHistory.listen(({ action }) => {
  //     console.log("action", action);
  //     if (action === 'PUSH') {
        
  //     } else {
        
  //     }
  //   });
  // }, []);
  const pathLocation = useLocation();
  const url = pathLocation.href;

  return (
    <Layout>
      <Seo 
        title="Sogody - Best-in-class Martech company" 
        image={'https://sogody.com'+metaImageOg} 
        description="Sogody is working on engineering digital experiences to have clients convert without pitching. Our team is dedicated to creating and improving the user experience, interfaces, and applications to complement any modern business challenge."
        url={url}
      /> 
      <HomepageCarousel/>
      <Clients/>
      <Services/>
      {/* <ShopigodyBox/> */}
      <CaseStudies/> 
      <Container>
        <CtaMeet/>
      </Container>
      <StoreezSpell/>
      <Testimonials/>
      <Updates/>
      <TermsBanner/>
    </Layout>

)
  }


